import { Color } from "enums/Common";
import { EChartsOption } from "echarts";
import { IRTMAnalysisData } from "interfaces/KPI";
import RTMAnalysisChartWrapper from "components/RTMAnalysis/RTMAnalysisChartWrapper/RTMAnalysisChartWrapper";

interface AgeGroupChartProps {
  data: IRTMAnalysisData["patientAge"];
}

const AgeGroupChart = ({ data }: AgeGroupChartProps) => {
  const getOptions = (): EChartsOption => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{d}%", // show percentage
      },
      legend: {
        top: 15,
        left: "60%",
        orient: "vertical",
        icon: "square",
        itemWidth: 12,
        align: "left",
        itemGap: 8,
        textStyle: {
          fontFamily: "Cabin",
          fontSize: 12,
          lineHeight: 15,
          color: Color.Nero,
        },
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          type: "pie",
          right: "50%",
          radius: "100%",
          label: {
            show: false,
          },
          emphasis: { scale: false },
          data: [
            {
              value: data["0-15"],
              name: "0 - 15",
              itemStyle: { color: Color.Endeavour },
            },
            {
              value: data["16-30"],
              name: "16 - 30",
              itemStyle: { color: Color.Tumbleweed },
            },
            {
              value: data["31-50"],
              name: "31 - 50",
              itemStyle: { color: Color.TropicalRainForest },
            },
            {
              value: data["51-65"],
              name: "51 - 65",
              itemStyle: { color: Color.PictonBlue },
            },
            {
              value: data["66-75"],
              name: "66 - 75",
              itemStyle: { color: Color.VividViolet },
            },
            {
              value: data["76-80"],
              name: "76 - 80",
              itemStyle: { color: Color.Apple },
            },
            {
              value: data["81+"],
              name: "81+",
              itemStyle: { color: Color.BlueWhale },
            },
          ],
        },
      ],
    };
  };

  return (
    <RTMAnalysisChartWrapper
      isEmpty={!Object.keys(data).length}
      title="Current RKM Patients by Age Group"
      chartOptions={getOptions()}
    />
  );
};

export default AgeGroupChart;
