import { IKPIAccount, IRTMAnalysisData } from "interfaces/KPI";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import AgeGroupChart from "components/RTMAnalysis/AgeGroupChart/AgeGroupChart";
import ClaimStatusChart from "components/RTMAnalysis/ClaimStatusChart/ClaimStatusChart";
import DebouncedAutocomplete from "components/DebouncedAutocomplete/DebouncedAutocomplete";
import KPIService from "services/KPIService";
import Loading from "components/Loading/Loading";
import NewRTMOrdersChart from "components/RTMAnalysis/NewRTMOrdersChart/NewRTMOrdersChart";
import OrdersByDurationChart from "components/RTMAnalysis/OrdersByDurationChart/OrdersByDurationChart";
import PayerTypeChart from "components/RTMAnalysis/PayerTypeChart/PayerTypeChart";
import RTMOrdersByMonthChart from "components/RTMAnalysis/RTMOrdersByMonthChart/RTMOrdersByMonthChart";
import styles from "./RTMAnalysis.module.scss";

interface RTMAnalysisProps {
  selectedAccount: IKPIAccount | null;
  onAccountChange: (value: IKPIAccount | null) => void;
  onFetchFail: () => void;
}

const RTMAnalysis = (props: RTMAnalysisProps) => {
  const { selectedAccount, onAccountChange, onFetchFail } = props;

  const [data, setData] = useState<IRTMAnalysisData>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const controller = new AbortController();

    KPIService.getRTMAnalysisData(
      { clientId: selectedAccount?.id },
      controller.signal
    )
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;

        onFetchFail();
        setLoading(false);
      });

    return () => controller.abort();
  }, [selectedAccount, onFetchFail]);

  const handleGetAccountOptions = (
    name: string | undefined
  ): Promise<AxiosResponse<IKPIAccount[]>> => {
    // API requires at least 3 characters
    if (!name || name.length < 3) return Promise.reject();

    // transform response data to array of objects (API returns object with shape {[accountId]: accountName})
    return KPIService.getClients({ name: name ?? "" }).then((response) => ({
      ...response,
      data: Object.keys(response.data).map((key) => ({
        id: key,
        name: response.data[key],
      })),
    }));
  };

  return (
    <div className="bg-white rounded-[9px] rounded-tr-3xl overflow-hidden">
      <div className="bg-pelorous p-1 pl-5 text-base leading-5 text-white font-medium flex items-center justify-between">
        RKM Analysis
        <DebouncedAutocomplete<IKPIAccount>
          className={styles.client_autocomplete}
          placeholder="Search by Account Name (minimum 3 characters)"
          getOptionLabel={({ name }) => name}
          getOptions={handleGetAccountOptions}
          value={selectedAccount}
          onChange={onAccountChange}
          renderOption={(props, { name }) => (
            <li {...props} key={name}>
              <p>{name}</p>
            </li>
          )}
        />
      </div>

      <div className="p-6">
        <p className="mb-7 text-center text-cloudBurst font-bold text-2xl">
          {`Accounts: ${selectedAccount?.name ?? "All"}`}
        </p>

        <div className="relative grid min-h-[33vh] gap-[30px] grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
          {data && (
            <>
              <NewRTMOrdersChart data={data.newOrders} />
              <RTMOrdersByMonthChart data={data.monthlyOrders} />
              <OrdersByDurationChart data={data.byDuration} />
              <ClaimStatusChart data={data.claims} />
              <AgeGroupChart data={data.patientAge} />
              <PayerTypeChart data={data.payerType} />
            </>
          )}

          <Loading loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default RTMAnalysis;
