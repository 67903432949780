import { Dispatch, SetStateAction, useCallback } from "react";
import { IAccountStatistics, IAccountStatisticsFilters } from "interfaces/KPI";

import { ITableColumn } from "interfaces/Common";
import KPIService from "services/KPIService";
import Loading from "components/Loading/Loading";
import NoTableItems from "components/Tables/NoTableItems/NoTableItems";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "components/TableSortLabel/TableSortLabel";
import kpiTableStyles from "styles/shared/kpiTable.module.scss";
import useFetchTableData from "hooks/useFetchTableData";

interface RTMStatisticsProps {
  filters: IAccountStatisticsFilters;
  setFilters: Dispatch<SetStateAction<IAccountStatisticsFilters>>;
  onFetchFail: () => void;
}

const topHeaderCells: { label: string; colSpan?: number }[] = [
  { label: "" },
  { label: "" },
  { label: "" },
  { label: "Orders", colSpan: 3 },
  { label: "# Tests Performed", colSpan: 2 },
];

const bottomHeaderCells: ITableColumn[] = [
  { label: "Account Name", sortProperty: "name" },
  { label: "Ordering Clinicians", sortProperty: "clinicians" },
  { label: "Total Patients", sortProperty: "patients" },
  { label: "Current", sortProperty: "current_orders" },
  { label: "Expired", sortProperty: "expired_orders" },
  { label: "Billable Months\nOrdered", sortProperty: "months_ordered" },
  { label: "Gait", sortProperty: "gait" },
  { label: "Balance", sortProperty: "number" },
];

const RTMStatistics = (props: RTMStatisticsProps) => {
  const { filters, setFilters, onFetchFail } = props;

  const onPageIncrement = useCallback(
    () => setFilters((prev) => ({ ...prev, page: prev.page + 1 })),
    [setFilters]
  );

  const { data, loading, lastElementRef } = useFetchTableData<
    IAccountStatistics,
    IAccountStatisticsFilters
  >({
    filters,
    onPageIncrement,
    fetchAPI: KPIService.getRTMStatistics,
    onError: onFetchFail,
  });

  return (
    <div className="relative p-6 bg-white rounded-[9px]">
      <p className="mb-2 text-cloudBurst text-sm leading-4 font-medium">
        RKM Statistics by Account
      </p>
      <TableContainer className={kpiTableStyles.kpi_table_container}>
        <Table>
          <TableHead>
            <TableRow>
              {topHeaderCells.map(({ label, colSpan }, index) => (
                <TableCell key={index} colSpan={colSpan}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {bottomHeaderCells.map(({ label, sortProperty }) => (
                <TableCell key={label}>
                  {sortProperty ? (
                    <TableSortLabel
                      whiteTheme
                      label={label}
                      sortProperty={sortProperty}
                      orderBy={filters._order_by}
                      onSort={(value) =>
                        setFilters((prev) => ({
                          ...prev,
                          page: 1,
                          _order_by: value,
                        }))
                      }
                    />
                  ) : (
                    label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              (
                {
                  id,
                  name,
                  clinicians,
                  patients,
                  expiredOrders,
                  currentOrders,
                  monthsOrdered,
                  gait,
                  balance,
                },
                index
              ) => (
                <TableRow
                  key={id}
                  ref={index === data.length - 1 ? lastElementRef : null}
                >
                  <TableCell>{name}</TableCell>
                  <TableCell>{clinicians.toLocaleString()}</TableCell>
                  <TableCell>{patients.toLocaleString()}</TableCell>
                  <TableCell>{currentOrders.toLocaleString()}</TableCell>
                  <TableCell>{expiredOrders.toLocaleString()}</TableCell>
                  <TableCell>{monthsOrdered.toLocaleString()}</TableCell>
                  <TableCell>{gait.toLocaleString()}</TableCell>
                  <TableCell>{balance.toLocaleString()}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!data.length && !loading && (
        <NoTableItems
          className="mt-5"
          icon="icon_no_results"
          message={
            filters.id
              ? "No Statistics for selected Account"
              : "No Accounts to show"
          }
        />
      )}

      <Loading loading={loading} />
    </div>
  );
};

export default RTMStatistics;
