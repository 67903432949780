import { generatePath, useNavigate } from "react-router-dom";

import ButtonCreateOrder from "../../Buttons/ButtonCreateOrder/ButtonCreateOrder";
import { Color } from "../../../enums/Common";
import DashboardCardContainer from "../../DashboardCardContainer/DashboardCardContainer";
import { EChartsOption } from "echarts";
import { IActiveOrdersData } from "../../../interfaces/Order";
import { IClinician } from "../../../interfaces/Clinician";
import ReactECharts from "echarts-for-react";
import { URLRoutes } from "../../../enums/Routing";
import moment from "moment";
import { useMemo } from "react";

interface ICurrentOrdersCardProps {
  data?: IActiveOrdersData;
  clinician?: IClinician;
}

const CurrentOrdersCard = ({ data, clinician }: ICurrentOrdersCardProps) => {
  const navigate = useNavigate();

  // if there were not active orders in certain month, API will not return it in list
  const chartData = useMemo((): IActiveOrdersData["data"] => {
    if (!data) return [];

    const tempChartData: IActiveOrdersData["data"] = [];

    const currentDate = moment();
    let month = currentDate.month() + 1; // month() returns 0-11
    let year = currentDate.year();

    // create dummy data for last 6 months with 0 count
    for (let i = 0; i < 6; i++) {
      tempChartData.push({ year, month, count: 0 });

      // decrement month
      if (month === 1) {
        month = 12;
        year--;
      } else {
        month--;
      }
    }

    // replace dummy data with API data for months that are returned fom API
    for (const { count, month, year } of data.data) {
      const index = tempChartData.findIndex(
        (x) => x.month === month && x.year === year
      );

      if (index !== -1) {
        tempChartData[index].count = count;
      }
    }

    // save data in time ascending order
    return tempChartData.reverse();
  }, [data]);

  const chartOption = useMemo((): EChartsOption => {
    const axisLabel = {
      padding: 8,
      color: Color.Nero,
      fontSize: 12,
      fontFamily: "Cabin",
    };

    return {
      xAxis: {
        type: "category",
        data: chartData.map(({ month }) =>
          moment()
            // - 1 => "moment": Months are zero indexed, so January is month 0
            .month(month - 1)
            .format("MMM")
        ),
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: { color: Color.VeryLightGrey },
        },
        axisLabel,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: { color: Color.VeryLightGrey },
        },
      },
      series: [
        {
          data: chartData.map(({ count }) => count),
          type: "line",
          symbolSize: 9,
          lineStyle: { width: 3, color: Color.Pelorous },
        },
      ],
      tooltip: { trigger: "axis" },
      grid: {
        top: 23,
        right: 23,
        bottom: 23,
        left: 23,
        containLabel: true,
      },
    };
  }, [chartData]);

  return (
    <DashboardCardContainer
      title="Current RKM orders"
      childrenClassName="flex flex-col gap-5"
      children={
        <>
          <div className="mt-2.5 flex gap-2 items-center">
            <p className="text-2xl font-bold text-tropicalRainForest">
              {data?.currentCount ?? "-"}
            </p>

            <ButtonCreateOrder
              preselectedClinician={clinician}
              onCompleted={() =>
                navigate(
                  clinician
                    ? generatePath(URLRoutes.ClinicianOrders, {
                        clinicianId: clinician.id,
                      })
                    : URLRoutes.OrdersDashboard
                )
              }
            />
          </div>
          <div className="mx-2.5 grow h-0 bg-endeavour/10 rounded-lg flex justify-center items-center">
            {data && (
              <>
                {chartData.every(({ count }) => count === 0) ? (
                  <p className="text-lg text-cloudBurst font-bold">
                    No orders in past 6 months
                  </p>
                ) : (
                  <ReactECharts
                    style={{ height: "100%", width: "100%" }}
                    option={chartOption}
                  />
                )}
              </>
            )}
          </div>
        </>
      }
    />
  );
};

export default CurrentOrdersCard;
