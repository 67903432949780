import { Color } from "enums/Common";
import { EChartsOption } from "echarts";
import { IRTMAnalysisData } from "interfaces/KPI";
import { PayerType } from "enums/Order";
import RTMAnalysisChartWrapper from "components/RTMAnalysis/RTMAnalysisChartWrapper/RTMAnalysisChartWrapper";

interface PayerTypeChartProps {
  data: IRTMAnalysisData["payerType"];
}

const PayerTypeChart = ({ data }: PayerTypeChartProps) => {
  const getOptions = (): EChartsOption => {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{d}%", // show percentage
      },
      legend: {
        top: 15,
        left: "60%",
        orient: "vertical",
        icon: "square",
        itemWidth: 12,
        align: "left",
        itemGap: 8,
        textStyle: {
          fontFamily: "Cabin",
          fontSize: 12,
          lineHeight: 15,
          color: Color.Nero,
        },
      },
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          type: "pie",
          right: "50%",
          radius: ["70%", "100%"],
          label: {
            show: false,
          },
          emphasis: { scale: false },
          data: [
            {
              value: data[PayerType.Commercial],
              name: "Commercial",
              itemStyle: { color: Color.Endeavour },
            },
            {
              value: data[PayerType.Medicare],
              name: "Medicare",
              itemStyle: { color: Color.Tumbleweed },
            },
            {
              value: data[PayerType.Medicaid],
              name: "Medicaid",
              itemStyle: { color: Color.TropicalRainForest },
            },
            {
              value: data[PayerType.PersonalInjury],
              name: "Personal Injury",
              itemStyle: { color: Color.PictonBlue },
            },
            {
              value: data[PayerType.SelfPay],
              name: "Self Pay",
              itemStyle: { color: Color.VividViolet },
            },
            {
              value: data[PayerType.WorkersCompensation],
              name: "Workers Comp",
              itemStyle: { color: Color.Apple },
            },
          ],
        },
      ],
    };
  };

  return (
    <RTMAnalysisChartWrapper
      isEmpty={!Object.keys(data).length}
      title="RKM Payer Type by Patient"
      chartOptions={getOptions()}
    />
  );
};

export default PayerTypeChart;
