import AccountAnalysisChart from "components/AccountAnalysis/AccountAnalysisChart/AccountAnalysisChart";
import AccountAnalysisCount from "components/AccountAnalysis/AccountAnalysisCount/AccountAnalysisCount";
import { IKPIAccountData } from "interfaces/KPI";

interface AccountAnalysisProps {
  data: IKPIAccountData["overview"];
}

const AccountAnalysis = ({ data }: AccountAnalysisProps) => {
  const { patients, tests, monthsOrdered, products } = data;

  return (
    <div className="bg-white rounded-[9px] overflow-hidden">
      <div className="bg-pelorous py-3.5 px-5 text-base leading-5 text-white font-medium">
        Account Analysis
      </div>
      <div className="pt-3.5 pr-6 pb-5 pl-6 lg:pr-14">
        <p className="mb-2 text-cloudBurst text-sm leading-4 font-medium">
          All Accounts by Product
        </p>

        <div className="flex flex-col gap-9 lg:flex-row">
          <AccountAnalysisChart data={products} />

          <div className="mt-2 flex flex-col gap-6">
            <AccountAnalysisCount count={patients} label="Patients" />
            <AccountAnalysisCount count={tests} label="Tests Performed" />
            <AccountAnalysisCount
              count={monthsOrdered}
              label="RKM Months Ordered"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountAnalysis;
