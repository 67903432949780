import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Color } from "../../../enums/Common";
import DashboardCardContainer from "../../DashboardCardContainer/DashboardCardContainer";
import { EChartsOption } from "echarts";
import { PatientActiveOrderData } from "../../../interfaces/Patient";
import ReactECharts from "echarts-for-react";
import { URLRoutes } from "../../../enums/Routing";
import moment from "moment";
import { useMemo } from "react";

interface OrderExpirationCardProps {
  data?: PatientActiveOrderData;
}

const OrderExpirationCard = ({ data }: OrderExpirationCardProps) => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  const noActiveOrder = useMemo((): boolean => data === null, [data]);

  // difference between current and start date
  const daysSpent = moment().diff(
    moment(data?.startDate, "YYYY-MM-DD"),
    "days"
  );

  // difference between end and current date
  const daysLeft = moment(data?.endDate, "YYYY-MM-DD").diff(
    moment.now(),
    "days"
  );
  const monthsLeft = moment(data?.endDate, "YYYY-MM-DD").diff(
    moment.now(),
    "months",
    true
  );

  const lessThanMonthRemaining = monthsLeft < 1;
  const valueToShow = lessThanMonthRemaining ? daysLeft : Math.ceil(monthsLeft);

  const chartOptions = useMemo((): EChartsOption => {
    return {
      series: [
        {
          type: "pie",
          radius: ["70%", "100%"],
          cursor: "default",
          emphasis: {
            disabled: true,
          },
          labelLine: { show: false },
          data: [
            {
              value: daysSpent,
              itemStyle: {
                color: lessThanMonthRemaining
                  ? Color.Crimson
                  : Color.TropicalRainForest,
              },
            },
            { value: daysLeft, itemStyle: { color: Color.PattensBlue } },
          ],
        },
      ],
    };
  }, [daysLeft, daysSpent, lessThanMonthRemaining]);

  return (
    <DashboardCardContainer
      childrenClassName="relative flex justify-center items-center"
      title="RKM Order expires in"
      {...(data
        ? {
            buttonData: {
              text: "View Order",
              onClick: () =>
                navigate(
                  generatePath(URLRoutes.PatientOrderDetails, {
                    patientId: patientId!,
                    orderId: data.id,
                  })
                ),
            },
          }
        : {})}
      children={
        data !== undefined && (
          <>
            {noActiveOrder ? (
              <p className="text-lg text-cloudBurst font-bold">
                The patient does not have an active order
              </p>
            ) : (
              <>
                <ReactECharts
                  style={{ height: "100%", width: "100%" }}
                  option={chartOptions}
                />
                <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-pre-wrap text-center text-endeavour text-base leading-5">
                  <span className="font-bold text-3xl leading-8">
                    {valueToShow}
                  </span>
                  {`\n${lessThanMonthRemaining ? "day" : "month"}${
                    valueToShow === 1 ? "" : "s"
                  }\nremaining`}
                </p>
              </>
            )}
          </>
        )
      }
    />
  );
};

export default OrderExpirationCard;
