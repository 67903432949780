import {
  AccountAuth,
  Auth,
  IsBillingCompany,
  IsClientAdmin,
  IsInternalClient,
  IsSuperAdmin,
} from "recoil-states/auth-states";
import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import AuthService from "services/AuthService";
import { ISidebarItem } from "interfaces/Common";
import LocalStorageService from "services/LocalStorageService";
import SidebarItem from "components/SidebarItem/SidebarItem";
import { URLRoutes } from "enums/Routing";
import biomechFullLogo from "assets/images/biomech_full_logo.svg";
import styles from "./Sidebar.module.scss";

interface ISidebar {
  showGradientLine?: boolean;
  closeMobileMenu?: () => void;
}

const Sidebar = ({ showGradientLine, closeMobileMenu }: ISidebar) => {
  const isClientAdmin = useRecoilValue(IsClientAdmin);
  const isSuperAdmin = useRecoilValue(IsSuperAdmin);
  const isBillingCompany = useRecoilValue(IsBillingCompany);
  const isInternalClient = useRecoilValue(IsInternalClient);
  const setAuth = useSetRecoilState(Auth);
  const setAccountAuth = useSetRecoilState(AccountAuth);

  const handleLogout = useCallback(() => {
    AuthService.logout().then(() => {
      LocalStorageService.removeAuth();
      LocalStorageService.removeAccountAuth();
      setAuth(undefined);
      setAccountAuth(undefined);
    });
  }, [setAuth, setAccountAuth]);

  const topSidebarItems = useMemo((): ISidebarItem[] => {
    return [
      {
        icon: "icon_billing",
        label: "Billing",
        to: URLRoutes.BillingDashboard,
        hidden: !isBillingCompany,
      },
      {
        icon: "icon_account",
        label: "Account",
        to: URLRoutes.AccountDashboard,
        hidden: isBillingCompany,
      },
      {
        icon: "icon_patients",
        label: "Patients",
        to: URLRoutes.PatientsDashboard,
        hidden: isBillingCompany,
      },
      {
        icon: "icon_clinicians",
        label: "Clinicians",
        to: URLRoutes.CliniciansDashboard,
        hidden: isBillingCompany,
      },
      {
        icon: "icon_orders",
        label: "RKM Orders",
        to: URLRoutes.OrdersDashboard,
        hidden: isBillingCompany,
      },
      {
        icon: "icon_kpis",
        label: "KPIs",
        to: URLRoutes.KPIDashboard,
        hidden: !isInternalClient,
      },
      {
        icon: "icon_admin",
        label: "Admin",
        to: URLRoutes.AdminDashboard,
        hidden: !isClientAdmin && !isBillingCompany,
      },
      {
        icon: "icon_operations",
        label: "Operations",
        hidden: !isSuperAdmin || isBillingCompany,
        submenuItems: [
          { name: "Sensors", to: URLRoutes.OperationsDashboard },
          { name: "Accounts", to: URLRoutes.Accounts },
          { name: "Patients", to: URLRoutes.PatientsOperations },
          { name: "Billing", to: URLRoutes.BillingDashboard },
        ],
      },
    ];
  }, [isClientAdmin, isSuperAdmin, isBillingCompany, isInternalClient]);

  const bottomidebarItems = useMemo((): ISidebarItem[] => {
    return [
      {
        icon: "icon_profile",
        to: URLRoutes.Profile,
        label: "Profile",
      },
      {
        icon: "icon_log_out",
        label: "Log out",
        onClick: handleLogout,
      },
    ];
  }, [handleLogout]);

  const handleSidebarItemClick = useCallback(
    (onClick?: () => void) => {
      onClick?.();
      closeMobileMenu?.();
    },
    [closeMobileMenu]
  );

  return (
    <div
      className={`${
        showGradientLine ? styles.gradient_line : ""
      } overflow-y-auto py-5 h-full flex flex-col gap-3 justify-between bg-gradient-to-b from-prussianBlue via-endeavour to-pelorous`}
    >
      <div className="space-y-4">
        <img
          src={biomechFullLogo}
          className="h-6 md:portrait:h-4 lg:h-4 mx-auto"
          alt="logo"
        />
        <div className="space-y-2.5">
          {topSidebarItems.map((item) => (
            <SidebarItem
              key={item.label}
              {...item}
              onClick={() => handleSidebarItemClick(item.onClick)}
            />
          ))}
        </div>
      </div>

      <div className="space-y-2.5">
        {bottomidebarItems.map((item) => (
          <SidebarItem
            key={item.label}
            {...item}
            onClick={() => handleSidebarItemClick(item.onClick)}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
